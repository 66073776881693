import React, {useState} from 'react'

function Inputs(props) {

// console.log(props.images.length);
  return (
    <div className='row justify-content-md-center'>

    {props.images.map( (im,idx) => {
        // let im = require(path).default

        return(
            // <div key={idx}>
              <img id={"input"+idx} key={idx}  className='col-1 mb-3' src={im} style={{width:'100px'}}/>
            // </div>
            // im
        )
    }
  )}
    </div>
  )
}

export default Inputs