import React, { PureComponent, useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {class_names} from '../class_names'


const COLORS = ['#2fb380', '#3459e6', '#f8765f', '#f4bd61', '#287bb5', '#6f42c1'];

// const RADIAN = Math.PI / 180;
// const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
//   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
//   const x = cx + radius * Math.cos(-midAngle * RADIAN);
//   const y = cy + radius * Math.sin(-midAngle * RADIAN);

//   return (
//     <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
//       {`${(percent * 100).toFixed(0)}%`}
//     </text>
//   );
// };
const threshold = 0.05

const sumArray = (array) => {
    let sum = 0 // the sum is initialed to 0

  for (let i = 0; i < 
  array.length; i ++) {
  // take every item in the array and add it to sum variable
  sum += array[i]
  }

  // return sum
  return sum
}
const sumIfHigher = (array, val) => {
    let sum = 0 // the sum is initialed to 0

  for (let i = 0; i < 
  array.length; i ++) {
  // take every item in the array and add it to sum variable
  if (array[i]>=val) sum += array[i]
  }

  // return sum
  return sum
}

const ReLU = (array) => {
  for (let i = 0; i < array.length; i ++) {
      if (array[i]<=0) array[i] = 0;
  }
  return array
}



function Grafico(props) {

    // console.log(props.predictions);
    var len = props.predictions.length;
    var indices = new Array(len);
    let preds = props.predictions
    // console.log(preds);
    
    const idx_real = class_names.findIndex(cn => cn === props.mol)
    
    const shapes = preds.map( (p,idx) => {return idx===idx_real ? 0 : p/(1.0001-preds[idx_real])})
    // shapes[idx_real] = 0
    // console.log(idx_real);
    // console.log(shapes);
    
    for (let i = 0; i < len; ++i) indices[i] = i;
    indices = indices.sort(function (a, b) { return shapes[a] < shapes[b] ? 1 : shapes[a] > shapes[b] ? -1 : 0; });
    // console.log(indices);

    var topValues = []
    var candidates = []
    for (let i = 0; i < 5; i++){
        topValues[i] = shapes[indices[i]]
        candidates[i] = class_names[indices[i]]
    } 
    // console.log(candidates);
    // console.log(topValues);
    const ppales_sum = sumIfHigher(topValues, threshold)
    const resto_sum = sumArray(shapes) - ppales_sum

    var data = [];

    for (let i = 0; i < 5; i++){
        if (topValues[i]>=threshold) data.push({name: candidates[i], value: (topValues[i]*100).toFixed(2)})
    } 
    data.push({name: 'Others', value: (resto_sum*100).toFixed(2)})


      /*global ChemDoodle*/
      /*global callback1*/
      /*global callback2*/
      // ChemDoodle.io.file.content('http://molserver.feliperomero.es/mol/'+props.mol,callback1 );
      ChemDoodle.io.file.content('/molserver/mol/'+candidates[0]+'.mol',callback2 );
      if (candidates[0] != undefined) document.getElementById('molResult').innerHTML = candidates[0]

    const viewMol = (e) => {
      if (e.name !== 'Others'){
        ChemDoodle.io.file.content('/molserver/mol/'+e.name+'.mol',callback2 );
        document.getElementById('molResult').innerHTML = e.name
      }
    }
    return (
      <>
      { props.predictions.length > 0 ? 
        <ResponsiveContainer width="100%" height="100%">
        <BarChart
        // width={'100%'}
        //   height={'100%'}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dy={20} interval={0} tick={{fontSize: 12}} angle={-45} dataKey="name" height={55}/>
          <YAxis domain={[0, 100]} unit={'%'}/>
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="value" fill="#3459e6" onClick={e => viewMol(e)}/>
          {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
          </BarChart>
          </ResponsiveContainer>
        :
        ""
      }
      </>
    );
  
}


export default Grafico
