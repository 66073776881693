import TopBar from './components/TopBar';
import React, {useState, useEffect} from 'react'
import * as tf from '@tensorflow/tfjs';
// import modelUrl from './neural/model.json'




import './styles/App.css';
import { Button } from 'react-bootstrap';
import Config from './components/Config';
import NeuralNetwork from './components/NeuralNetwork';
import {class_names} from './class_names'




function App() {
  // const model = tf.loadGraphModel(modelUrl);
  
  // const modelUrl = '/neural/model.json'
  // const model = tf.loadLayersModel(modelUrl);
  const modelUrl = '/neural/model.json'
  const model = tf.loadLayersModel(modelUrl);


  const [batch, setBatch] = useState(0)
  const [mol, setMol] = useState("");
  const [imgs, setImgs] = useState([])
  const [paths, setPaths] = useState([])
  const [predictions, setPredictions] = useState([])
  const [loading, setloading] = useState(false)
  const [computing, setComputing] = useState(false)
  const [scale, setScale] = useState(0)

 

  const getMol = async (molecule, n_projections) => {
    var res = await fetch(`/molserver/?mol=${molecule}&num=${n_projections}`, {method:'GET'    });
    
    // console.log(await res.text());
    return await res.text();
  }

  const predict = async () =>  {
    var scale_arr  = [] 
    var tensors_arr = []
    // const init = (await model).predict([tf.ones([1,256,256,3]), tf.ones([1,1])],{batchSize: parseInt(batch), verbose: true})
    for (let i = 0; i<batch; i++){
      // var im = document.getElementById("input"+i);
      var scale = parseInt(paths[i].split('_')[1])   
      scale_arr.push(scale)
      tensors_arr.push(tf.browser.fromPixels(imgs[i],3).mul(tf.scalar(1/255)))
    }
    const img_batch = tf.stack(tensors_arr)
    const scale_batch = tf.stack(scale_arr)
    console.log( 
        `Successful conversion from array of images to a ${img_batch.shape} tensor`
      )
  
  let preds = (await model).predict([img_batch, scale_batch],{batchSize: parseInt(batch), verbose: true})
  preds = preds.softmax()
  preds.print();

  setPredictions(preds.mean(0).dataSync())
  setComputing(false)
}
  

  const selectBatch = async (b) => {
    setloading(true)
    setBatch(b);
    var projections = await getMol(mol, b);
    projections = projections.split('<br>')
    var img_arr = new Array();
    var paths_arr = []
    for (let i=0; i<b; i++){
      img_arr[i] = document.createElement("img");
      img_arr[i].crossOrigin='anonymous'
      img_arr[i].src='/molserver/'+projections[i];

      paths_arr.push(img_arr[i].src)
      img_arr[i].width = 256
      img_arr[i].height = 256
      }
      
    setImgs(img_arr);
    setPaths(paths_arr);
    setScale(parseInt(paths_arr[0].split('_')[1]))
    setloading(false)

  }

  const [trained, setTrained] = useState(true)

  const check_if_trained = (e) => {
    const mol_found = class_names.find(m => e.target.value == m)

    if (mol_found === undefined) setTrained(false);
    else setTrained(true);
    

  }

  const update_chemDoodle = () => {
    /*global ChemDoodle*/
    /*global callback1*/
    // /*global callback2*/
    ChemDoodle.io.file.content('/molserver/mol/'+mol+'.mol',callback1 );

    document.getElementById('molOriginal').innerHTML = mol
  }

  return (
    <div className="App">
      {/* <Helmet>
        <title>Molecule Finder</title>
        <script async type="text/javascript" src="./ChemDoodleWeb.js"></script>
        <script async type="text/javascript" src="./visor.js"></script>
      </Helmet> */}
      <TopBar mol={mol} selectMol={setMol} selectBatch={selectBatch} trained={trained} check={check_if_trained} updateVisor={update_chemDoodle}/>
      <button disabled={mol == "" || imgs.length == 0 || loading ? true : false} className={computing ? 'btn btn-primary btn-lg mt-4 disabled' : 'btn btn-primary btn-lg mt-4' } onClick={() => {setComputing(true); predict();}}>{computing ? "Computing" : "Compute" }</button>
      <NeuralNetwork loading={loading} computing={computing} mol={mol} images={paths} batch={batch} predictions={predictions} scale={scale}/>

      <Config  batch={batch} mol={mol} selectMol={setMol} selectBatch={selectBatch} trained={trained} check={check_if_trained} updateVisor={update_chemDoodle}/>

      
  </div>

  );
}

export default App;
