import React, {useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleUp} from '@fortawesome/free-solid-svg-icons'
import {faAngleDown} from '@fortawesome/free-solid-svg-icons'

function Config(props) {
  
  const [show, setshow] = useState(false)

  return (
    <div className='config'>
      <div className='row justify-content-end align-items-center'>
        <div className='col col-2 slide d-flex justify-content-center align-items-center' onClick={() => setshow(!show)}>
          <b>Config Panel</b>
          {show ? 
            <FontAwesomeIcon icon={faAngleDown} style={{ marginLeft:"1rem", width: "30px", height:"30px"}}/>
          :
            <FontAwesomeIcon icon={faAngleUp} style={{ marginLeft:"1rem", width: "30px", height:"30px"}}/>
          } 
        </div>
      </div>
      <div className={show ? 'panel' : 'd-none'}>
            <div className="container my-3">
              <div className='row'>
                <div className='col-sm-3 form-group'>
                  <label  className="form-label text-light">Molecule: </label>
                  <input
                    className={!props.trained ? "form-control form-control-lg is-invalid" : "form-control form-control-lg"}
                    // style={!props.trained ? {borderColor: '#da292e'} : {borderColor: '#343a40'}}
                    type="text"
                    placeholder="Molecule code"
                    id="inputLarge"
                    onFocus={(e)=>e.target.value = 'DB'}
                    value={props.mol}
                    onChange={(e)=>{
                      props.selectMol(e.target.value)
                      props.check(e)
                    }}
                    onBlur={props.updateVisor}
                    />
                  {props.trained ? <div></div> : <div className="text-danger">The neural network was not trained with this molecule</div>}
                </div>
                <div className='col-sm-3 form-group'>
                  <label className="form-label text-light">Number of projections:</label>
                  <input type="number" disabled={props.mol == "" ? true : false} min="1" max="128" step="1" className="form-control form-control-lg" id="customRange1" value={props.batch}  onChange={(e)=>props.selectBatch(e.target.value)}/>
                </div>
                <div className='col-sm-3'>
                  <label className="form-label text-light">Fast batch size</label>
                  <br></br>
                  <button className='btn btn-lg btn-primary w-100' disabled={props.mol == "" ? true : false}  value={8} onClick={(e)=>props.selectBatch(e.target.value)}>Set batch size to 8</button>
                </div>
                <div className='col-sm-3 form-group'>
                  <label className="form-label text-light">Database of molecules</label>
                    <select className="form-select form-control-lg" id="exampleSelect1">
                      <option>DrugBank</option>
                    </select>
                  </div>
              </div>

            </div>
      
            <fieldset className="form-group">
              <div className='d-flex'>
                <span className='mx-3 text-light'>1</span>
                <input type="range" disabled={props.mol == "" ? true : false} min="1" max="256" step="1" className="form-range" id="customRange1" value={props.batch}  onChange={(e)=>props.selectBatch(e.target.value)}/>
                <span className='mx-3 text-light'>256</span>
              </div>
            </fieldset>

        </div>
    </div>
  )
}

export default Config