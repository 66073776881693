import React from 'react'
import estructura from '../img/estructura.png';
import Inputs from './Inputs';
import Grafico from './Grafico';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Spinner from 'react-bootstrap/Spinner';
import Comparator from '../components/Comparator';
import {class_names} from '../class_names'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function NeuralNetwork(props) {

  return (
    <div className='nn'>
        <div className='input'>
            <div className='d-flex justify-content-center align-items-center'>
              <h1 className='mx-3'>Inputs </h1>
              <Spinner className={props.loading ? 'd-block' : 'd-none'} animation="border" variant="primary" />
              
            </div>
            
            
            {props.mol ? props.scale != 0 ? <h5>{props.mol} (Scale: {props.scale})</h5> : <h5>{props.mol}</h5> : ""}
              <Inputs className='d-inline' images={props.images} batch={props.batch} />
        </div>
        <div className='structure'>
            <div className='d-flex justify-content-center align-items-center'>
              <h1 className='mx-3'>MolNet2D </h1>
              <Spinner className={props.computing ? 'd-block' : 'd-none'} animation="border" variant="primary" />
              
            </div>
            <Tabs
              defaultActiveKey="structure"
              id="fill-tab-example"
              className="mb-3"
              fill
              variant='tabs'
            >
              <Tab eventKey="structure" title="Structure">
                <img src={estructura} style={{width:'100%'}}/>
                <div className={props.computing ? "arrow" : "d-none"} >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    {/* <span></span> */}
              </div>
              </Tab>
              <Tab eventKey="outputs" title="Display">
                <Comparator></Comparator>
              </Tab>
              <Tab eventKey="instructions" title="Instructions" tabClassName=''>
                <ol className="list-group list-group-numbered">
                  <li className="list-group-item" style={{textAlign: 'left'}}>Slide the <b className="badge bg-dark">Config Panel</b> downside or use the input placed on the top bar.</li>
                  <li className="list-group-item" style={{textAlign: 'left'}}>Select a <b>molecule</b> from the DrugBank by writing its code </li>
                  <li className="list-group-item" style={{textAlign: 'left'}}>Choose the <b>batch size</b> (number of projections). The larger the batch size, the higher the accuracy, but it also increases the computational effort.</li>
                  <li className="list-group-item" style={{textAlign: 'left'}}><b className="badge bg-primary">Compute</b> to get the outputs (<b>predictions</b>) of the Neural Network.</li>
                  <li className="list-group-item" style={{textAlign: 'left'}}>Click on a <b>bar</b> of the output plot and go to the <b className="badge bg-primary">Display</b> tab to check if its similarity is acceptable. </li>
                </ol>
              </Tab>
            </Tabs>
            
        </div>
        <div className='output'>
            <h1 className='mt-3'>Predictions</h1>
            <Grafico predictions = {props.predictions} mol={props.mol}/>
        </div>
    </div>
  )
}

export default NeuralNetwork