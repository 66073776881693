import React, {useEffect} from 'react'



const Comparator = () => {


    useEffect(() => {
        /*global ChemDoodle*/
        /*global cargaInicial*/
        /*global nombre1*/
        /*global nombre2*/
        const script = document.createElement('script');
      
        script.type = 'text/javascript';
        script.text = "var wh=300; \
        let canvas1 = new ChemDoodle.TransformCanvas3D('canvas1', wh, wh); \
        let canvas2 = new ChemDoodle.TransformCanvas3D('canvas2', wh, wh); \
        cargaInicial(canvas1,nombre1);\
        cargaInicial(canvas2,nombre2);";
      
        document.body.appendChild(script);

        // document.getElementById('canvas1').className = 'mx-auto'
        // document.getElementById('canvas2').className = 'mx-auto'


        return () => {
          document.body.removeChild(script);
        }
      }, []);
    

  return (
    <>
    <div id='visor' className='d-flex flex-row justify-content-center mt-3'>
        <div className='d-flex flex-column justify-content-center mx-1'>
            <h4 id='molOriginal'>Undefined</h4>
            <canvas id="canvas1" className='mx-auto' width="300" height="300">This is a message that shows if the browser doesn't support HTML5 canvas, which all modern browsers should support now.</canvas>
        </div>
        <div className='d-flex flex-column mx-1'>
            <h4 id='molResult'>Undefined</h4>
            <canvas id="canvas2" className='mx-auto' width="300" height="300">This is a message that shows if the browser doesn't support HTML5 canvas, which all modern browsers should support now.</canvas>
        </div>
    </div>
    <div className='mt-2'>
      <input className="form-check-input" type='checkbox' id='cb'  defaultChecked/>
      <span className='mx-2 h5'>Move both</span>
    </div>
    </>
  )
}

export default Comparator