import React from 'react'
import logo from '../img/logo.png';
import logo_ual from '../img/logo_almeria.png';


function TopBar(props) {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
        <div className="container-fluid">
          <a className="navbar-brand p-0" href="#">
            <span>Molecule Finder</span>
            <img src={logo} style={{width: '40px'}}/>
          </a>
          <div className='ml-auto'>
              <img src={logo_ual} style={{width: '150px'}}/>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarColor01"
            aria-controls="navbarColor01"
            aria-expanded="false"
            aria-label="Toggle navigation"
            >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarColor01">
            <ul className="navbar-nav me-auto">
              <li className="nav-item">
                <a className="nav-link active" href="#">
                  Supervised classifier
                  <span className="visually-hidden">(current)</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link disabled" href="#">
                  Clustering
                </a>
              </li>
            </ul>
            <form className="d-flex">
              <input
                className={props.trained ? "form-control me-sm-2" : "form-control is-invalid me-sm-2"}
                type="text"
                placeholder="Molecule code"
                onFocus={(e)=>e.target.value = 'DB'}
                value={props.mol}
                onChange={(e)=>{
                  props.selectMol(e.target.value)
                  props.check(e)
                }}
                onBlur={props.updateVisor}
                />
              <div className={props.trained ? "btn btn-secondary my-2 my-sm-0" : "btn btn-warning my-2 my-sm-0"} onClick={() => props.selectBatch(8)}>
                Search
              </div>
            </form>
            
          </div>
        </div>
      </nav>
      
  )
}

export default TopBar